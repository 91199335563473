import { NavLink } from "react-router-dom";
import SideNavIcon from "../assets/images/sideNavIcon.svg";
import times from "../assets/images/times.svg";
import { useState } from "react";

const NavBar = ({ isAuthenticated }) => {
  const [openAndCloseSideNav, setOpenAndCloseSideNav] = useState(false);
  const onOpenAndCloseClick = () => {
    setOpenAndCloseSideNav(!openAndCloseSideNav);
  };

  return (
    <div className="flex md:justify-center xs:justify-end items-center bg-primary text-white uppercase h-12 px-6">
      {isAuthenticated ? (
        <>
          <div className="md:flex xs:hidden text-sm">
            <NavLink to="/" className=" lg:mx-10 md:mx-6 ease-in duration-300">
              Home
            </NavLink>
            <NavLink
              to="schedule"
              className=" lg:mx-10 md:mx-6 ease-in duration-300"
            >
              Schedule
            </NavLink>

            <NavLink
              to="travel"
              className="lg:mx-10 md:mx-6 ease-in duration-300"
            >
              Travel
            </NavLink>

            <NavLink
              to="registry"
              className="lg:mx-10 md:mx-6 ease-in duration-300"
            >
              Registry
            </NavLink>

            <NavLink to="faq" className="lg:mx-10 md:mx-6 ease-in duration-300">
              Faq
            </NavLink>
          </div>

          <div className="md:hidden xs:flex">
            <button onClick={onOpenAndCloseClick}>
              <img className="" src={SideNavIcon} alt="side nav icon" />
            </button>
          </div>
        </>
      ) : null}

      <div
        className={`overflow-y-auto p-5 ease-in duration-200 fixed top-0 bottom-0 right-0 w-screen bg-primary ${
          openAndCloseSideNav ? "translate-x-[0]" : "translate-x-[100%]"
        } flex flex-wrap items-between `}
      >
        <div className="w-full text-end ">
          <button onClick={onOpenAndCloseClick}>
            <img className="" src={times} alt="times nav icon" />
          </button>
        </div>
        <div className="w-full text-center">
          <ul className="">
            <li className="my-10">
              <NavLink
                onClick={onOpenAndCloseClick}
                to="/"
                className="ease-in duration-300 tracking-[5px]"
              >
                Home
              </NavLink>
            </li>
            <li className="my-10">
              <NavLink
                onClick={onOpenAndCloseClick}
                to="schedule"
                className="ease-in duration-300 tracking-[5px]"
              >
                Schedule
              </NavLink>
            </li>

            <li className="my-10">
              <NavLink
                onClick={onOpenAndCloseClick}
                to="travel"
                className="ease-in duration-300 tracking-[5px]"
              >
                Travel
              </NavLink>
            </li>

            <li className="my-10">
              <NavLink
                onClick={onOpenAndCloseClick}
                to="registry"
                className="ease-in duration-300 tracking-[5px]"
              >
                Registry
              </NavLink>
            </li>

            <li className="my-10">
              <NavLink
                onClick={onOpenAndCloseClick}
                to="faq"
                className="ease-in duration-300 tracking-[5px]"
              >
                Faq
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
