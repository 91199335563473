import "./App.css";
import { BrowserRouter } from "react-router-dom";
import NavBar from "./components/NavBar";

import { useEffect, useState } from "react";
import isAuthenticated from "./utils/isAuthenticated";
import RenderRoutes from "./components/Routes/RenderRoutes";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState(null);
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);

  const onAutChange = () => {
    if (localStorage.userType) {
      setIsLoggedIn(isAuthenticated());
      setUserType(localStorage.userType);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      if (window.scrollY > window.innerHeight) {
        setShowBackToTopButton(true);
      } else {
        setShowBackToTopButton(false);
      }
    });
    onAutChange();
  }, []);
  return (
    <BrowserRouter>
      <header>
        <NavBar isAuthenticated={isLoggedIn} />
      </header>
      <main className="">
        <RenderRoutes userType={userType} onAutChange={onAutChange} />
      </main>
      <footer
        className={`ease-in duration-300 bg-primary w-full h-[80px] flex items-center px-10 ${
          isLoggedIn && showBackToTopButton
            ? "justify-between"
            : "justify-center"
        }  text-white`}
      >
        <div className="text-xl uppercase ease-in duration-300 md:flex xs:hidden">
          Yassin and Natasha
        </div>
        {isLoggedIn && showBackToTopButton && (
          <div className="ease-in duration-300 text-center md:w-auto xs:w-full  text-center">
            <button
              className="uppercase"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              back to top
            </button>
          </div>
        )}

        {isLoggedIn && !showBackToTopButton && (
          <div className="uppercase ease-in duration-300 text-center md:hidden md:w-auto xs:w-full text-center">
            Yassin and Natasha
          </div>
        )}
      </footer>
    </BrowserRouter>
  );
}

export default App;
